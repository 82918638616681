<script setup lang="ts">
const onboardingStore = useOnboardingStore();
const cartStore = useCartStore();

const { promoCode } = storeToRefs(cartStore);
const { onReferralRedemptionLimit } = storeToRefs(onboardingStore);

const apply = () => {
  window.location.href = `${window.location.origin}${window.location.pathname}?pc=HEALTHYBABY`;
};

const isVisible = computed(
  () =>
    promoCode &&
    promoCode.value &&
    promoCode.value?.code &&
    onReferralRedemptionLimit.value
);
</script>

<template>
  <div
    v-if="isVisible"
    class="tw-bg-nl-green-100 tw-h-[45px] tw-text-nl-yellow-100 tw-font-agenda tw-flex tw-items-center tw-justify-center tw-uppercase tw-font-semibold tw-tracking-wide tw-text-center tw-leading-[1.04] tw-px-5"
  >
    <button type="button" @click="apply">
      This referral code has reached its redemption limit. Use code HEALTHYBABY
      instead to get 50% OFF your first week!
      <span class="tw-font-bold">Click to apply.</span>
    </button>
  </div>
</template>
<style scoped>
@import url("~/assets/css/app.css");
</style>
