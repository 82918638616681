<script setup lang="ts">
import { getNumberSuffix, maybePluralize } from "~/utils/helpers.js";
import type { PromoCodeTier } from "~/types/promoCode";

const props = defineProps({
  tier: {
    type: Object as PropType<PromoCodeTier>,
    required: true,
  },
});
</script>

<template>
  <span
    class="tw-font-agenda tw-text-[12px] tw-font-semibold tw-text-nl-yellow-100 sm:tw-whitespace-nowrap sm:tw-text-base"
  >
    <span class="tw-hidden sm:tw-inline">
      {{
        `${props.tier.weekly_ordinal_number}${getNumberSuffix(
          props.tier.weekly_ordinal_number
        )}`
      }}
      week:
    </span>
    <span v-show="props.tier.type === 'dollars_off'" class="tw-font-bold">
      ${{ props.tier.amount }} OFF
    </span>
    <span v-show="props.tier.type === 'percent_off'" class="tw-font-bold">
      {{ props.tier.amount }}% OFF
    </span>
    <span v-show="props.tier.type === 'products_off'" class="tw-font-bold">
      {{ props.tier.amount }}
      {{ maybePluralize(props.tier.amount, 'item') }} OFF
    </span>
  </span>
</template>
<style scoped>
@import url('~/assets/css/app.css');
</style>
